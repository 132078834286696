import { loader as popupsLoader } from "./popups.js";
import { submit as dynamicSearchSubmit } from "./dynamic-search.js"
import { debounce } from "./utilities.js";

const init = function() {
	loader();
	text();
	selectsAndChecks();
	loaderSubmit();
	autosubmit();
	maxLength();
	autocomplete();
	showIf.init();
	datepicker();
}

const loader = function() {

	var targets = $('.js-form-submit-loader');

	targets.on('submit', function() {
		popupsLoader();
	});

}

const text = function() {
	$('form:not(.js-form-text-submit-done):not(.js-form-ignore-validation)').each(function() {
		var form = $(this);
		form.on('submit', function() {
			if (form.valid()) {
				loader();
				return;
			}

			var inputs = form.find('input:not([type="checkbox"],[type="submit"])[required="required"]');

			if (!inputs.length) {
				return false;
			}
			inputs.each(function() {
				var target = $(this);
				var message = '';
				if (!target.val() && target.attr('data-val-required')) {
					message = target.attr('data-val-required');
				} else if (target.attr('data-val-equalto')) {
					message = target.attr('data-val-equalto');
				}

				if (message) {
					var errorElement = target.next('.form-error');

					if (target.is('.input-validation-error')) {
						errorElement.show().html(message);
					} else {
						errorElement.hide().html('');
					}
				}
			});

			return false;
		});

		// done 
		form.addClass('js-form-text-submit-done');
	});
}

const selectsAndChecks = function() {
	$('form:not(.js-form-submit-done):not(.js-form-ignore-validation)').each(function() {
		var form = $(this);
		var inputs = form.find('.select select');

		var _check = function(input) {
			var parent = input.closest('.select');
			if (!input.valid()) {
				parent.addClass('select-validation-error');
			}
			else {
				parent.removeClass('select-validation-error');
			}
		}

		// submit
		form.on('submit', function() {
			// select validation
			inputs.each(function() {
				_check($(this));
			});
		});

		// change
		inputs.on('change', function() {
			_check($(this));
		});

		var checkboxes = form.find('input[type="checkbox"][required="required"]');

		if (checkboxes.length) {

			// submit
			form.on('submit', function() {

				checkboxes.each(function() {

					var target = $(this),
						message = target.attr('data-val-required'),
						errorElement = target.parent().next('.form-error');

					if (target.is('.input-validation-error')) { errorElement.show().html(message); }
					else { errorElement.hide().html(''); }

				});

			});

		}

		// done
		form.addClass('js-form-submit-done');
	});
}

const loaderSubmit = function() {
	$('form.js-loader-submit:not(.js-loader-submit-done)').each(function() {
		var form = $(this);

		// submit
		form.on('submit', function() {
			// check valid
			if (form.valid()) {
				// show loader
				popupsLoader();
				return true;
			}
			return false;
		});

		// done
		form.addClass('js-loader-submit-done');
	});
}

const autosubmit = function() {

	// links
	$('a.js-autosubmit:not(.js-autosubmit-done)').each(function() {
		var link = $(this);

		// click
		link.on('click', function() {
			// show loader
			popupsLoader();
		});

		// done
		link.addClass('js-autosubmit-done');
	});

	// checks/radios
	$(':checkbox.js-autosubmit:not(.js-autosubmit-done), :radio.js-autosubmit:not(.js-autosubmit-done)').each(function() {
		var input = $(this);
		var form = input.closest('form');

		// change
		input.on('change', function() {
			// check valid
			if (form.valid()) {
				// show loader
				popupsLoader();
				form.submit();
			}
			return false;
		});

		// done
		input.addClass('js-autosubmit-done');
	});

	// selects
	$('select.js-autosubmit').each(function() {
		var input = $(this);
		var form = input.closest('form');

		// change
		input.on('change', function() {
			// check valid
			if (form.valid()) {
				// show loader
				popupsLoader();
				form.submit();
			}
			return false;
		});

		// done
		input.addClass('js-autosubmit-done');
	});

	// datepicker
	$('input.js-datepicker.js-autosubmit').each(function() {
		var input = $(this);
		var form = input.closest('form');
		var value = input.val();

		// change
		input.datepicker('option', 'onClose', function() {
			if (value != input.val()) {
				// check valid
				if (form.valid()) {
					// show loader
					popupsLoader();
					form.submit();
				}
			}
		});

		// done
		input.addClass('js-autosubmit-done');
	});

}

const datepicker = function() {

	$('input.js-datepicker:not(.js-datepicker-done)').each(function() {
		var input = $(this);

		// init
		input.datepicker({
			dateFormat: 'dd/mm/yy',
			showAnim: '',
			onSelect: function() {

				// dynamic search
				if ($(this).is('.js-dynamic-search-when')) dynamicSearchSubmit(false);

			}
		});

		// set readonly
		input.attr('readonly', 'readonly');

		// min
		if (typeof input.attr('data-min') != 'undefined') {
			input.datepicker('option', 'minDate', input.attr('data-min'));
		}

		// max
		if (typeof input.attr('data-max') != 'undefined') {
			input.datepicker('option', 'maxDate', input.attr('data-max'));
		}

		// done
		input.addClass('js-datepicker-done');
	});
}

const maxLength = function() {
	$('input[data-maxlength]:not(.js-max-done), textarea[data-maxlength]:not(.js-max-done)').each(function() {
		var input = $(this);
		var count = $(input.attr('data-maxlength-info'));
		// get limit
		var limit = parseInt(input.attr('data-maxlength'), 10);
		// limit on keyup, click, paste or blur
		input.on('keyup click paste blur', function() {
			var remaining = limit - input.val().length;
			if (remaining <= 0) {
				input.val(input.val().substr(0, limit));
				remaining = 0;
			}
			count.text(remaining);
		}).blur();
		// done
		input.addClass('js-max-done');
	});
}

const autocomplete = function() {

	$('input[data-autocomplete]:not(.js-autocomplete-done)').each(function() {
		var input = $(this);

		// init
		input.autocomplete({
			source: input.attr('data-autocomplete'),
			minLength: 3,
			delay: 100
		}).attr('autocomplete', 'autocomplete_off');

		// resize
		window.site.win.on('resize', debounce(function() {
			if (input.data('ui-autocomplete') !== undefined) { input.autocomplete('close'); }
		}))

		// done
		input.addClass('js-autocomplete-done');
	});

}

const showIf = {

	init: function() {
		showIf.checks();
		showIf.radios();
		showIf.dropdowns.preInit();
	},

	disableFormElements: function(parent) {
		parent.find("input,select").attr('disabled', 'disabled');
	},

	enableFormElements: function(parent) {
		parent.find("input,select").removeAttr('disabled');
	},

	checks: function() {
		$('.form-checks input[data-show-if]:checkbox:not(.js-show-if-done)').each(function() {
			var input = $(this);
			var reverse = input.attr('data-reverse') === 'true' ? true : false;
			var ref = input.attr('data-show-if').split(',');

			var check = function() {
				if (!input.is(':checked') && reverse) {
					// show
					for (var i = 0; i < ref.length; i++) {
						$(ref[i]).show();
						showIf.enableFormElements($(ref[i]));
					}
				}
				else if (input.is(':checked') && reverse) {
					// hide
					for (var j = 0; j < ref.length; j++) {
						$(ref[j]).hide();
						showIf.disableFormElements($(ref[j]));
					}
				}
				else if (input.is(':checked')) {
					// show
					for (var k = 0; k < ref.length; k++) {
						$(ref[k]).show();
						showIf.enableFormElements($(ref[k]));
					}
				}
				else {
					// hide
					for (var l = 0; l < ref.length; l++) {
						$(ref[l]).hide();
						showIf.disableFormElements($(ref[l]));
					}
				}
			};

			// change functionality
			input.on('change', function() {
				check();
			});

			// on load
			check();

			// done
			input.addClass('js-show-if-done');
		});
	},

	radios: function() {
		$('.form-checks:not(.js-show-if-done) input[data-show-if]:radio').closest('.form-checks').each(function() {
			var wrapper = $(this);

			// change functionality
			wrapper.find('input').each(function() {
				var input = $(this);
				var group = input.attr('name');

				var check = function(opt, show) {
					var ref = opt.attr('data-show-if').split(',');
					if (show) {
						for (var i = 0; i < ref.length; i++) {
							$(ref[i]).show();
							showIf.enableFormElements($(ref[i]));
						}
					}
					else {
						for (var i = 0; i < ref.length; i++) {
							$(ref[i]).hide();
							showIf.disableFormElements($(ref[i]));
						}
					}
				};

				// on change
				input.on('change', function() {
					// hide non-selected
					$('input[name="' + group + '"][data-show-if]:not(:checked)').each(function() {
						// hide
						check($(this), false);
					});
					// show selected
					$('input[name="' + group + '"][data-show-if]:checked').each(function() {
						// show
						check($(this), true);
					});
				});

				// on load
				if (input.is('[data-show-if]:not(:checked)')) {
					// hide
					check($(this), false);
				}
				if (input.is('[data-show-if]:checked')) {
					// show
					check($(this), true);
				}

			});

			// done
			wrapper.addClass('js-show-if-done');
		});
	},

	dropdowns: {

		preInit: function() {
			// set attributes
			$('select[data-show-if]:not(.js-show-if-init)').each(function() {
				var select = $(this);
				var values = $.parseJSON($("<div/>").html(select.attr('data-show-if')).text());

				// apply attributes
				for (var i = 0; i < values.length; i++) {
					select.find('option[value="' + values[i].value + '"]').attr('data-show-if', values[i].show);
				}

				// done
				select.addClass('js-show-if-init');
			});

			// continue
			showIf.dropdowns.init();
		},

		init: function() {
			$('select:not(.js-show-if-done) option[data-show-if]').closest('select').each(function() {
				var select = $(this);

				var check = function(opt, show) {
					var ref = opt.attr('data-show-if').split(',');
					if (show) {
						for (var i = 0; i < ref.length; i++) {
							$(ref[i]).show();
							showIf.enableFormElements($(ref[i]));
						}
					}
					else {
						for (var j = 0; j < ref.length; j++) {
							$(ref[j]).hide();
							showIf.disableFormElements($(ref[j]));
						}
					}
				}

				// change functionality
				select.on('change', function() {
					// hide non-selected
					select.find('option[data-show-if]').each(function() {
						var opt = $(this);
						if (opt.is(':selected')) {
							check(opt, true);
						}
						else {
							check(opt, false);
						}
					});
				});

				// on load
				select.find('option[data-show-if]').each(function() {
					var opt = $(this);
					if (opt.is(':selected')) {
						check(opt, true);
					}
					else {
						check(opt, false);
					}
				});

				// done
				select.addClass('js-show-if-done');
			});
		}

	}

}

export default init;
